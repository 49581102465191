$('.js-new-arrival-slider').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  arrow: true,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        centerMode: true
      }
    }
  ]
})

$('.js-gallery-slide').slick({
  slidesToShow: 3,
  slidesToScroll: 1,
  arrow: true,
  variableWidth: true,
  infinite: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        variableWidth: true,
        slidesToShow: 1
      }
    }
  ]
})