const menuToggle = document.getElementById("menu-toggle");
const target = document.getElementById("header-toggle-nav");
const headerNavItems = document.getElementsByClassName( "js-header-nav-item" );

const searchToggle = document.querySelector(".js-toggle-search");
const searchContent = document.querySelector(".js-toggle-search-content");

let toggleOpen = false;
let toggleSearchOpen = false;


menuToggle.addEventListener('click', (e) => {
  if (!toggleOpen) {
    target.classList.add('header-open');
    menuToggle.classList.add('toggle-on');
    toggleOpen = true;
  } else {
    target.classList.remove('header-open');
    menuToggle.classList.remove('toggle-on');
    toggleOpen = false;
  }
});

target.addEventListener('click', (e) => {
  if (!toggleSearchOpen) {
    target.classList.add('header-open');
    menuToggle.classList.add('toggle-on');
    toggleOpen = true;
  } else {
    target.classList.remove('header-open');
    menuToggle.classList.remove('toggle-on');
    toggleOpen = false;
  }
});

searchToggle.addEventListener('click', (e) => {
  if (!toggleSearchOpen) {
    searchContent.classList.add('search-open');
    searchToggle.classList.add('search-toggle-on');
    toggleSearchOpen = true;
  } else {
    searchContent.classList.remove('search-open');
    searchToggle.classList.remove('search-toggle-on');
    toggleSearchOpen = false;
  }
});

// searchContentToggle.addEventListener('click', (e) => {
//   if (!toggleSearchOpen) {
//     searchContent.classList.add('search-open');
//     searchToggle.classList.add('search-toggle-on');
//     toggleSearchOpen = true;
//   } else {
//     searchContent.classList.remove('search-open');
//     searchToggle.classList.remove('search-toggle-on');
//     toggleSearchOpen = false;
//   }
// });
